import * as React from "react"
import { Router } from '@reach/router';
import ToolsHome from './tools';
import ToolDetail from './tool-detail';

const Tools = () => {
  return (
    <div>
      <Router>
        <ToolsHome path="/tools" />
        <ToolDetail path="/tools/:id" />
      </Router>
    </div>
  )
}

export default Tools;
